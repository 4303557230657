import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvitationService } from '@app/core/services/invitation.service';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Invitation } from '@shared/models/invitation';
import { NgIf } from '@angular/common';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';

const TEN_SECONDS = 10000;

@Component({
    selector: 'packex-invitation-list',
    templateUrl: './invitation-list.component.html',
    styleUrls: ['./invitation-list.component.scss'],
    imports: [NgIf, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, ButtonComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, TranslocoPipe]
})
export class InvitationListComponent {
  @Input() invitations: Invitation[] = [];
  @Output() invitationsChanged = new EventEmitter<any>();

  displayedColumns: string[] = ['email', 'send', 'action'];
  disabled = false;

  constructor(
    private readonly invitationService: InvitationService,
    private readonly snackbarService: SnackBarService,
    private readonly dialog: MatDialog,
  ) {}

  public sendInvitation(invitation: Invitation) {
    if (!this.disabled) {
      this.invitationService.invite(invitation.email).subscribe(() => {
        this.snackbarService.showSimpleSuccess(
          translate('ORGANIZATION.SEND_INVITATION.SENT', {
            email: invitation.email,
          }),
        );
        this.disable();
      });
    }
  }

  private disable(): void {
    this.disabled = true;

    setTimeout(() => {
      this.disabled = false;
    }, TEN_SECONDS);
  }

  public deleteInvitation(invitation: Invitation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'ORGANIZATION.DELETE_INVITATION_DIALOG.TITLE',
          rawContent: translate(
            'ORGANIZATION.DELETE_INVITATION_DIALOG.CONTENT',
            {
              email: invitation.email,
            },
          ),
          primaryButtonText: 'ORGANIZATION.DELETE_INVITATION_DIALOG.BUTTON',
        },
      })
      .afterClosed()
      .subscribe((confirmDelete) => {
        if (confirmDelete) {
          this.invitationService.decline(invitation.id).subscribe({
            next: () => {
              this.snackbarService.show({
                message: '',
                rawMessage: translate(
                  'ORGANIZATION.DELETE_INVITATION_DIALOG.SUCCESS',
                  {
                    email: invitation.email,
                  },
                ),
              });
              this.invitationsChanged.emit();
            },
            error: () => {
              this.snackbarService.showError({
                message: '',
                rawMessage: translate(
                  'ORGANIZATION.DELETE_INVITATION_DIALOG.ERROR',
                  {
                    email: invitation.email,
                  },
                ),
              });
            },
          });
        }
      });
  }
}
