import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/core/services/user.service';
import { validatePassword } from '@app/utils/validators';
import { ChangePasswordForm } from '@modules/user/components/change-password-form/change-password-form.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { User } from '@shared/models/user';
import { filter, of, switchMap } from 'rxjs';
import { ModalComponent as ModalComponent_1 } from '../../../../shared/components/modal/modal.component';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { NgIf } from '@angular/common';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

// TODO: fixme. replace with ErrorCode from Backend
export const oldPasswordIncorrectMessage = 'Invalid old password';

@Component({
    selector: 'packex-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [ModalComponent_1, FormsModule, ReactiveFormsModule, MatFormField, MatInput, IconComponent, MatSuffix, NgIf, MatError, ChangePasswordFormComponent, ButtonComponent, TranslocoPipe]
})
export class ChangePasswordModalComponent extends ModalComponent {
  form = new FormGroup<ChangePasswordForm>({
    oldPassword: new FormControl('', Validators.compose([Validators.required])),
    password: new FormControl(
      '',
      Validators.compose([Validators.required, validatePassword]),
    ),
    repeatPassword: new FormControl(
      '',
      Validators.compose([Validators.required, validatePassword]),
    ),
  });

  hideOldPassword = true;
  hidePassword = true;
  hideRepeatPassword = true;

  constructor(
    dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    private readonly snackBarService: SnackBarService,
    private readonly userService: UserService,
  ) {
    super(dialogRef);
  }

  get passwordsNotEqual(): boolean {
    return (
      this.form.get('password')?.value !==
      this.form.get('repeatPassword')?.value
    );
  }

  get oldPasswordError() {
    return this.form.get('oldPassword')
      ? 'USER.CHANGE_PASSWORD.OLD_PASSWORD_INVALID'
      : 'USER.CHANGE_PASSWORD.PASSWORD_COMPLEXITY';
  }

  public changePassword() {
    if (!this.form.valid || this.passwordsNotEqual) return;

    this.userService.user$
      .pipe(
        filter((user: User | null) => !!user),
        switchMap((user) => {
          if (user) {
            return this.userService.patchPassword(
              user.id as unknown as string,
              this.form.get('oldPassword')?.value,
              this.form.get('password')?.value,
            );
          } else {
            return of(false);
          }
        }),
      )
      .subscribe({
        next: () => {
          this.snackBarService.show({
            message: 'USER.CHANGE_PASSWORD.CHANGE_SUCCESS',
            buttons: [
              {
                label: 'COMMON.OKAY',
                cb: () => this.snackBarService.dismiss(),
              },
            ],
          });
          this.onConfirm();
        },
        error: (err) => {
          if (err.message === oldPasswordIncorrectMessage) {
            this.form.get('oldPassword')?.setErrors({
              invalidPassword: {
                valid: false,
              },
            });
          } else {
            this.snackBarService.showSimpleError('USER.CHANGE_PASSWORD.ERROR');
          }
        },
      });
  }
}
