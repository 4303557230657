import { Component, inject, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import { PageComponent } from '@shared/components/ui/page/page.component';
import { CardComponent } from '@shared/components/ui/card/card.component';
import { DividerComponent } from '@shared/components/ui/divider/divider.component';
import { RegistrationCompleteFormComponent } from '../registration-complete-form/registration-complete-form.component';
import { TranslocoPipe } from '@jsverse/transloco';
import Keycloak from 'keycloak-js';

@Component({
  selector: 'packex-complete-user',
  templateUrl: './complete-user.component.html',
  styleUrls: ['./complete-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    PageComponent,
    CardComponent,
    DividerComponent,
    RegistrationCompleteFormComponent,
    TranslocoPipe,
  ],
})
export class CompleteUserComponent {
  constructor(private readonly languageService: LanguageService) {
    const keycloak = inject(Keycloak);
    if (!keycloak.authenticated) {
      keycloak
        .login({
          locale: this.languageService.currentLanguage(),
        })
        .then(() => {});
    }
  }
}
