import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { ROUTES } from '@app/routes';
import { ChangePasswordFormComponent } from '@modules/user/components/change-password-form/change-password-form.component';
import { USER_PASSWORD_ROUTES } from '@modules/user/user-routing.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { ChangePasswordFormComponent as ChangePasswordFormComponent_1 } from '../change-password-form/change-password-form.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, ChangePasswordFormComponent_1, MatCheckbox, FormsModule, ReactiveFormsModule, ButtonComponent, TranslocoPipe]
})
export class SetPasswordComponent
  extends ChangePasswordFormComponent
  implements OnInit
{
  token?: string;

  email = '';
  marketingAcceptance = new FormControl(true);

  constructor(
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackbarService: SnackBarService,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(({ email, token }) => {
        this.email = email;
        this.token = token;
      });
  }

  get formIsValid() {
    return this.form.valid && !this.passwordsNotEqual;
  }

  public submit() {
    if (!this.formIsValid || !this.token) return false;

    return this.userService
      .changePassword(this.token, this.form.get('password')?.value as string)
      .subscribe({
        next: () => {
          this.router
            .navigateByUrl(
              `${ROUTES.USER}/${USER_PASSWORD_ROUTES.CHANGE_SUCCESS}`,
            )
            .then();
        },
        error: () => {
          this.snackbarService.showSimpleError('USER.CHANGE_PASSWORD.ERROR');
        },
      });
  }
}
