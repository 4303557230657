import { Component } from '@angular/core';
import { PageComponent } from '../../../shared/components/ui/page/page.component';
import { DividerComponent } from '../../../shared/components/ui/divider/divider.component';
import { OrganizationNameComponent } from '../organization-name/organization-name.component';
import { OrganizationColleaguesComponent } from '../organization-colleagues/organization-colleagues.component';
import { OrganizationAddressesComponent } from '../organization-addresses/organization-addresses.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
    imports: [PageComponent, DividerComponent, OrganizationNameComponent, OrganizationColleaguesComponent, OrganizationAddressesComponent, TranslocoPipe]
})
export class OrganizationComponent {}
