import { Component, ViewEncapsulation } from '@angular/core';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'packex-registration-page',
    templateUrl: './registration-page.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [PageComponent, RouterOutlet]
})
export class RegistrationPageComponent {}
