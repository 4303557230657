import { Component, OnInit, inject } from '@angular/core';
import { LanguageService } from '@app/core/services/language.service';
import Keycloak from 'keycloak-js';

@Component({
  selector: 'packex-user-login',
  template: '',
})
export class UserLoginComponent implements OnInit {
  constructor(private readonly languageService: LanguageService) {}

  ngOnInit() {
    (async () => {
      const keycloak = inject(Keycloak);
      keycloak.login({
        locale: this.languageService.currentLanguage(),
      });
    })();
  }
}
