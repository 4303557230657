import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '@app/core/services/contact.service';
import { UserService } from '@app/core/services/user.service';
import { validateEmail } from '@app/utils/validators';
import { ChangePasswordModalComponent } from '@modules/user/components/change-password-modal/change-password-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { LanguageSelectComponent } from '../../../../shared/components/packex-select/language-select.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-user-account',
    templateUrl: './user-account.component.html',
    styleUrls: ['./user-account.component.scss'],
    imports: [PageComponent, DividerComponent, CardComponent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, NgIf, MatError, ButtonComponent, LanguageSelectComponent, TranslocoPipe]
})
export class UserAccountComponent implements OnInit {
  loading = false;

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    department: new FormControl(''),
    jobTitle: new FormControl(''),
    email: new FormControl(
      '',
      Validators.compose([Validators.required, validateEmail]),
    ),
  });

  constructor(
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackBarService,
    private readonly contactService: ContactService,
  ) {}

  ngOnInit() {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.form.get('firstName')?.patchValue(user?.firstName || '');
      this.form.get('lastName')?.patchValue(user?.lastName || '');
      this.form.get('email')?.patchValue(user?.email || '');
      this.form.get('jobTitle')?.patchValue(user?.jobTitle || '');
      this.form.get('department')?.patchValue(user?.department || '');
    });
  }

  public openChangePasswordModal(): void {
    this.dialog.open(ChangePasswordModalComponent);
  }

  public save() {
    if (this.form.valid) {
      this.userService
        .updateUser({
          id: this.userService.user?.id,
          firstName: this.form.get('firstName')?.value as string,
          lastName: this.form.get('lastName')?.value as string,
          email: this.form.get('email')?.value as string,
          jobTitle: this.form.get('jobTitle')?.value as string,
          department: this.form.get('department')?.value as string,
        })
        .subscribe(() => {
          this.snackbarService.showSimpleSuccess('COMMON.CHANGES_SAVED');
        });
    }
  }

  public deleteAccount() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'USER.DELETE_ACCOUNT.TITLE',
          content: 'USER.DELETE_ACCOUNT.CONTENT',
          secondaryButtonText: 'COMMON.CANCEL',
          primaryButtonText: 'COMMON.DELETE',
        },
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          const user = this.userService.user;

          const formData = new FormData();
          formData.set('type', 'other');
          formData.set('subject', 'Anfrage Account aus Organisation entfernen');

          if (user?.organization.name) {
            formData.set('company', user?.organization.name);
          }

          if (user) {
            formData.set('firstName', String(user.firstName));
            formData.set('lastName', String(user.lastName));
            formData.set('email', String(user.email));
            formData.set(
              'message',
              `${user.firstName} ${user.lastName} ${user.id} (Organisation: ${user?.organization.name}) möchte, dass sein Account aus der Organisation entfernt wird.`,
            );
          }

          this.contactService.requestOffer(formData).subscribe({
            next: () => {
              this.snackbarService.showSimpleSuccess(
                'ORGANIZATION.DELETE_COLLEAGUE.SUCCESS',
              );
            },
            error: () => {
              this.snackbarService.showSimpleError('USER.ACCOUNT.DELETE_ERROR');
            },
          });
        }
      });
  }
}
