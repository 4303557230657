import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { User } from '@shared/models/user';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-registration-success-page',
    templateUrl: './registration-success-page.component.html',
    styleUrls: ['./registration-success-page.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, TranslocoPipe]
})
export class RegistrationSuccessPageComponent implements OnInit {
  user?: User;

  constructor(private userService: UserService, private router: Router) {
    // TODO: may refactor process here, we grab last created registration by service for now, non-link-safe
    // NOTE: reload will result in invalid view as we handle with non persisted user registration data
    /*    this.userRegistration = userService.getCreatedUserRegistration();
    if (!this.userRegistration) {
      // TODO: clarify missing registration for success view by redirect / common invalid view page
      this.router.navigate(['/']);
    }*/
  }

  ngOnInit() {
    this.userService.load().subscribe((user) => {
      this.user = user;
    });
  }

  onResend(): void {
    // FIXME: clarify endpoint for resend and params
    console.warn('resend mail for verification');
  }
}
