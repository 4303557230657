import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { ROUTES } from '@app/routes';
import { ChangePasswordFormComponent } from '@modules/user/components/change-password-form/change-password-form.component';
import { USER_PASSWORD_ROUTES } from '@modules/user/user-routing.module';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { ChangePasswordFormComponent as ChangePasswordFormComponent_1 } from '../change-password-form/change-password-form.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [PageComponent, CardComponent, DividerComponent, ChangePasswordFormComponent_1, ButtonComponent, TranslocoPipe]
})
export class ChangePasswordComponent
  extends ChangePasswordFormComponent
  implements OnInit
{
  token?: string;

  constructor(
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly snackbarService: SnackBarService,
  ) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.token = params['token'];
      });
  }

  public submit() {
    if (!this.form.valid || this.passwordsNotEqual || !this.token) return false;

    return this.userService
      .changePassword(this.token, this.form.get('password')?.value as string)
      .subscribe({
        next: () => {
          this.router
            .navigateByUrl(
              `${ROUTES.USER}/${USER_PASSWORD_ROUTES.CHANGE_SUCCESS}`,
            )
            .then();
        },
        error: () => {
          this.snackbarService.showSimpleError('USER.CHANGE_PASSWORD.ERROR');
        },
      });
  }
}
