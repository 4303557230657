import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactService } from '@app/core/services/contact.service';
import { InvitationService } from '@app/core/services/invitation.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserService } from '@app/core/services/user.service';
import { getLabelByTopic } from '@app/utils/notifications.helper';
import { OrganizationInviteWithStatusUpdatesModalComponent } from '@modules/organization/organization-invite-with-status-updates-modal/organization-invite-with-status-updates-modal.component';
import { translate, TranslocoPipe } from '@jsverse/transloco';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { ColleaguesDataSource } from '@shared/models/colleaguesDataSource';
import { Notification } from '@shared/models/notification';
import { orderBy } from 'lodash';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { UserInfoComponent } from '../../../shared/components/ui/user-info/user-info.component';
import { InventoryLabelComponent } from '../../inventory/components/label/label.component';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { IconComponent } from '../../../shared/components/icon/icon.component';

@Component({
    selector: 'packex-organization-status-receiver',
    templateUrl: './organization-status-receiver.component.html',
    styleUrls: ['./organization-status-receiver.component.scss'],
    imports: [NgIf, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, UserInfoComponent, NgFor, InventoryLabelComponent, NgTemplateOutlet, ButtonComponent, MatMenuTrigger, IconComponent, MatMenu, MatMenuItem, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, TranslocoPipe]
})
export class OrganizationStatusReceiverComponent implements OnInit {
  @Input() notifications!: Notification[];
  @Input() displayedColumns: string[] = ['user', 'topics', 'action'];
  @Input() showResendButton = true;

  @Output() notificationsChanged = new EventEmitter();

  dataSource?: ColleaguesDataSource[];

  constructor(
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly snackbarService: SnackBarService,
    private readonly userService: UserService,
    private readonly contactService: ContactService,
    private readonly invitationService: InvitationService,
  ) {}

  ngOnInit(): void {
    this.buildDataSource();
  }

  private buildDataSource(): void {
    this.dataSource = orderBy(
      this.notifications
        .sort((a, b) =>
          a.user?.lastName || '' > (b.user?.lastName || '') ? 1 : -1,
        )
        .map((notification: Notification) => ({
          notification,
          email: notification.email,
          user: notification.user,
          topicLabels: notification.topics.map((topic) =>
            getLabelByTopic(topic),
          ),
        })) || [],
      'user.lastName',
      'asc',
    );
  }

  public changeStatusUpdates(element: ColleaguesDataSource) {
    this.dialog
      .open(OrganizationInviteWithStatusUpdatesModalComponent, {
        data: {
          title: 'ORGANIZATION.EDIT_NOTIFICATIONS.TITLE',
          settingsDescription: 'ORGANIZATION.EDIT_NOTIFICATIONS.DESCRIPTION',
          button: 'COMMON.SAVE',
          notification: element.notification,
        },
      })
      .afterClosed()
      .subscribe((changed) => {
        if (changed) {
          this.notificationsChanged.emit();
        }
      });
  }

  public resendInvitation(element: ColleaguesDataSource) {
    this.invitationService.invite(element.notification.email).subscribe();
  }

  public deleteNotification(element: ColleaguesDataSource) {
    if (element.notification.user) {
      this.deleteColleague(element);
    } else {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'ORGANIZATION.DELETE_NOTIFICATION.TITLE',
            rawContent: translate('ORGANIZATION.DELETE_NOTIFICATION.CONTENT', {
              email: element.notification.email,
            }),
            primaryButtonText: 'ORGANIZATION.DELETE_NOTIFICATION.REMOVE',
          },
        })
        .afterClosed()
        .subscribe((confirm) => {
          if (confirm) {
            this.notificationService
              .deleteNotification(element.notification.id as string)
              .subscribe(() => {
                this.snackbarService.show({
                  message: '',
                  rawMessage: translate(
                    'ORGANIZATION.DELETE_NOTIFICATION.SUCCESS',
                    { email: element.notification.email },
                  ),
                });
                this.notificationsChanged.emit();
              });
          }
        });
    }
  }

  private deleteColleague(element: ColleaguesDataSource) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'ORGANIZATION.DELETE_COLLEAGUE.TITLE',
          rawContent: translate('ORGANIZATION.DELETE_COLLEAGUE.CONTENT', {
            user: `${element.notification.user?.firstName} ${element.notification.user?.lastName}`,
            email: element.notification.email,
          }),
          secondaryButtonText: 'COMMON.CANCEL',
          primaryButtonText: 'ORGANIZATION.DELETE_NOTIFICATION.REMOVE',
        },
      })
      .afterClosed()
      .subscribe({
        next: (confirm) => {
          if (confirm) {
            const user = this.userService.user;

            const formData = new FormData();
            formData.set('type', 'other');
            formData.set(
              'subject',
              'Anfrage Kollegen aus Organisation entfernen',
            );

            if (user?.organization.name) {
              formData.set('company', user?.organization.name);
            }

            if (user) {
              formData.set('firstName', String(user.firstName));
              formData.set('lastName', String(user.lastName));
              formData.set('email', String(user.email));
              formData.set(
                'message',
                `${user.firstName} ${user.lastName} ${user.id} (Organisation: ${user?.organization.name}) möchte, dass Benutzer “${element.notification.user?.firstName} ${element.notification.user?.lastName}” (${element.notification.user?.id}${element.notification.email}) aus der Organisation entfernt wird.`,
              );
            }

            this.contactService.requestOffer(formData).subscribe({
              next: () => {
                this.snackbarService.showSimpleSuccess(
                  'ORGANIZATION.DELETE_COLLEAGUE.SUCCESS',
                );
              },
              error: () => {
                this.snackbarService.showSimpleError(
                  'USER.ACCOUNT.DELETE_ERROR',
                );
              },
            });
          }
        },
      });
  }
}
