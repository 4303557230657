import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { filter } from 'rxjs';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { IconComponent } from '../icon/icon.component';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ConditionalPipe } from '../../pipes/conditional.pipe';

@UntilDestroy()
@Component({
  selector: 'packex-organization-language-switch',
  templateUrl: './packex-select.component.html',
  styleUrls: ['./packex-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    TranslocoDirective,
    MatFormField,
    NgIf,
    MatLabel,
    MatSelect,
    MatSelectTrigger,
    IconComponent,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    NgFor,
    NgClass,
    AsyncPipe,
    ConditionalPipe,
    TranslocoPipe,
  ],
})
export class OrganizationLanguageSwitchComponent
  extends PackexSelectComponent<{ value: LANGUAGE } & SelectOption>
  implements OnInit
{
  constructor(
    private readonly userService: UserService,
    private readonly languageService: LanguageService,
  ) {
    super();

    this.options$.next(this.languageService.availableLanguages);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.userService.user$
      .pipe(
        filter((user) => !!user),
        untilDestroyed(this),
      )
      .subscribe((user) => {
        this.current = this.languageService.availableLanguages.find(
          (option) => option.value === user?.organization.preferredLanguage,
        );
      });
  }
}
