import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { ROUTES } from '@app/routes';
import { validateEmail } from '@app/utils/validators';
import { USER_PASSWORD_ROUTES } from '@modules/user/user-routing.module';
import Keycloak from 'keycloak-js';
import { PageComponent } from '@shared/components/ui/page/page.component';
import { CardComponent } from '@shared/components/ui/card/card.component';
import { DividerComponent } from '@shared/components/ui/divider/divider.component';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '@shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'packex-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  imports: [
    PageComponent,
    CardComponent,
    DividerComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgIf,
    MatError,
    ButtonComponent,
    TranslocoPipe,
  ],
})
export class ResetPasswordComponent {
  form = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([Validators.required, validateEmail]),
    ),
  });

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
  ) {}

  public submit(): void {
    if (this.form.invalid) return;

    this.userService
      .resetPassword(this.form.value.email as string)
      .subscribe(() => {
        this.redirect();
      });
  }

  public async redirect() {
    await this.router.navigateByUrl(
      `${ROUTES.USER}/${USER_PASSWORD_ROUTES.POST_RECEIVED}?email=${this.form.value.email}`,
    );
  }

  public async backToLogin() {
    const keycloak = inject(Keycloak);
    keycloak.login({
      locale: this.languageService.currentLanguage(),
    });
  }
}
