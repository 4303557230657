import { Component, ViewEncapsulation } from '@angular/core';
import { LANGUAGE, LanguageService } from '@app/core/services/language.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PackexSelectComponent,
  SelectOption,
} from '@shared/components/packex-select/packex-select.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { IconComponent } from '../icon/icon.component';
import { MatInput } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ConditionalPipe } from '../../pipes/conditional.pipe';

@UntilDestroy()
@Component({
    selector: 'packex-language-select',
    templateUrl: './packex-select.component.html',
    styleUrls: ['./packex-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [TranslocoDirective, MatFormField, NgIf, MatLabel, MatSelect, MatSelectTrigger, IconComponent, MatInput, FormsModule, ReactiveFormsModule, MatOption, NgFor, NgClass, AsyncPipe, TranslocoPipe, ConditionalPipe]
})
export class LanguageSelectComponent extends PackexSelectComponent<
  { value: LANGUAGE } & SelectOption
> {
  constructor(private readonly languageService: LanguageService) {
    super();

    this.options$.next(this.languageService.availableLanguages);

    this.current = this.languageService.availableLanguages.find(
      (option) => option.value === this.languageService.currentLanguage(),
    );

    this._current.pipe(untilDestroyed(this)).subscribe((option) => {
      if (
        option &&
        option.value &&
        this.languageService.currentLanguage() !== option.value
      ) {
        this.languageService.switchLanguage(option.value);
      }
    });
  }
}
