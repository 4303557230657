import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/services/user.service';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-complete-email-verification',
    templateUrl: './complete-email-verification.component.html',
    styleUrls: ['./complete-email-verification.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, ButtonComponent, TranslocoPipe]
})
export class CompleteEmailVerificationComponent implements OnInit {
  loading = false;

  constructor(
    private readonly userService: UserService,
    private readonly snackbarService: SnackBarService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.user?.isEmailVerified) {
      this.router.navigate(['/']).then(() => {});
    }
  }

  get user() {
    return this.userService.user;
  }

  public resendVerificationEmail(): void {
    this.loading = true;

    this.userService.sendVerificationEmail().subscribe({
      next: () => {
        this.loading = false;
        this.snackbarService.showSimpleSuccess(
          'USER.VERIFY.VERIFY_SNACKBAR_CONTENT',
        );
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}
