import { Component } from '@angular/core';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { RegistrationCompleteFormComponent } from '../../components/registration-complete-form/registration-complete-form.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-registration-complete-page',
    templateUrl: './registration-complete-page.component.html',
    styleUrls: ['./registration-complete-page.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, RegistrationCompleteFormComponent, TranslocoPipe]
})
export class RegistrationCompletePageComponent {}
