import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LANGUAGE } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectOption } from '@shared/components/packex-select/packex-select.component';
import { SnackBarService } from '@shared/components/snackbar/snackbar.service';
import { switchMap } from 'rxjs';
import { CardComponent } from '../../../shared/components/ui/card/card.component';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { OrganizationLanguageSwitchComponent } from '../../../shared/components/packex-select/organization-language-switch.component';
import { ButtonComponent } from '../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@UntilDestroy()
@Component({
    selector: 'packex-organization-name',
    templateUrl: './organization-name.component.html',
    styleUrls: ['./organization-name.component.scss'],
    imports: [CardComponent, FormsModule, ReactiveFormsModule, MatFormField, MatInput, NgIf, MatError, OrganizationLanguageSwitchComponent, ButtonComponent, TranslocoPipe]
})
export class OrganizationNameComponent implements OnInit {
  form = new FormGroup({
    company: new FormControl('', Validators.required),
    preferredLanguage: new FormControl('', Validators.required),
  });

  constructor(
    private readonly userService: UserService,
    private readonly snackbarService: SnackBarService,
  ) {}

  ngOnInit(): void {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.form.get('company')?.patchValue(user?.organization.name || '');
      this.form
        .get('preferredLanguage')
        ?.patchValue(user?.organization.preferredLanguage || 'de');
    });
  }

  public save(): void {
    const name = this.form.get('company')?.value;
    const preferredLanguage = this.form.get('preferredLanguage')?.value || 'de';

    if (this.form.valid && name) {
      this.userService
        .updateOrganization({ name, preferredLanguage })
        .pipe(switchMap(() => this.userService.load()))
        .subscribe(() => {
          this.snackbarService.showSimpleSuccess('COMMON.CHANGES_SAVED');
        });
    }
  }

  public languageChanged(event: ({ value: LANGUAGE } & SelectOption) | null) {
    if (event) {
      this.form.get('preferredLanguage')?.patchValue(event.value);
    }
  }
}
