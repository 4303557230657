import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { User } from '@shared/models/user';
import { switchMap } from 'rxjs';
import { MatFormField, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '../../../../shared/components/ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-registration-complete-form',
    templateUrl: './registration-complete-form.component.html',
    styleUrls: ['./registration-complete-form.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatInput, NgIf, MatError, ButtonComponent, TranslocoPipe]
})
export class RegistrationCompleteFormComponent implements OnInit {
  @Input() loading = false;
  @Output() onSuccess = new EventEmitter<User>();
  @Output() onFail = new EventEmitter();

  form = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    organization: new FormControl('', Validators.required),
  });

  constructor(
    private userService: UserService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      this.form.get('firstName')?.patchValue(user?.firstName || '');
      this.form.get('lastName')?.patchValue(user?.lastName || '');

      if (!user?.organization?.hasDefaultName) {
        this.form
          .get('organization')
          ?.patchValue(user?.organization.name as string);
        this.form.get('organization')?.disable();
      }
    });

    this.userService.load().subscribe();
  }

  public submit(): void {
    const formData = this.form ? this.form.value : null;

    if (formData && this.form.valid) {
      this.registrationCompleteFormSubmit();
    }
  }

  public registrationCompleteFormSubmit(): void {
    this.loading = true;

    this.userService.updateUser({
      id: this.userService.user?.id,
      firstName: this.form.get('firstName')?.value as string,
      lastName: this.form.get('lastName')?.value as string,
      organizationName: this.form.get('organization')?.getRawValue() as string,
    }),
      this.userService
        .updateUser({
          id: this.userService.user?.id,
          firstName: this.form.get('firstName')?.value as string,
          lastName: this.form.get('lastName')?.value as string,
          organizationName: this.form
            .get('organization')
            ?.getRawValue() as string,
        })
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/').then(() => {
              this.loading = false;
            });
          },
          error: () => (this.loading = false),
        });
  }
}
