import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/core/services/user.service';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-post-received',
    templateUrl: './post-received.component.html',
    styleUrls: ['./post-received.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, TranslocoPipe]
})
export class PostReceivedComponent implements OnInit {
  email?: string;

  constructor(
    private readonly userService: UserService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(({ email }) => (this.email = email));
  }

  public resend(): void {
    this.userService.resetPassword(this.email as string).subscribe(() => {});
  }
}
