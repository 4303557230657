import { Component } from '@angular/core';
import { ROUTES } from '@app/routes';
import { PageComponent } from '../../../../shared/components/ui/page/page.component';
import { CardComponent } from '../../../../shared/components/ui/card/card.component';
import { DividerComponent } from '../../../../shared/components/ui/divider/divider.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-change-password-success',
    templateUrl: './change-password-success.component.html',
    styleUrls: ['./change-password-success.component.scss'],
    imports: [PageComponent, CardComponent, DividerComponent, TranslocoPipe]
})
export class ChangePasswordSuccessComponent {
  myProductsLink = ROUTES.INVENTORY;
}
