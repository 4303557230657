import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { validatePassword } from '@app/utils/validators';
import { MatFormField, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { IconComponent } from '../../../../shared/components/icon/icon.component';
import { NgIf } from '@angular/common';
import { TranslocoPipe } from '@jsverse/transloco';

export interface ChangePasswordForm {
  oldPassword?: FormControl;
  password: FormControl;
  repeatPassword: FormControl;
}

@Component({
    selector: 'packex-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.scss'],
    imports: [FormsModule, ReactiveFormsModule, MatFormField, MatInput, IconComponent, MatSuffix, NgIf, MatError, TranslocoPipe]
})
export class ChangePasswordFormComponent {
  @Input() form = new FormGroup<ChangePasswordForm>({
    password: new FormControl('', validatePassword),
    repeatPassword: new FormControl('', validatePassword),
  });

  hideOldPassword = true;
  hidePassword = true;
  hideRepeatPassword = true;

  get passwordsNotEqual(): boolean {
    return (
      this.form.get('password')?.value !==
      this.form.get('repeatPassword')?.value
    );
  }
}
